import { useQueries, useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'
import { find, omit, pick } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { useUI } from 'src/layout/UI'
import { useTranslation } from 'react-i18next'

import { FoldersSections } from 'src/folders/FoldersPage'

import { supabase } from 'src/supabase'
import { useDoctors, useProfile, useTranscriber } from 'src/profiles/ProfilesApi'
import { useAuth } from 'src/auth/Auth'
import transcriptionsFilter from 'src/transcription/transcriptionsFilter'
import { useSettings } from 'src/settings/SettingsApi'

const { APP_API_URL } = import.meta.env

const delay = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds))
const stringifyIds = (data) => Object.keys(data).reduce((a, b) => ({ ...a, [b]: b.includes('id') && !!data[b] ? String(data[b]) : data[b] }), {})

const setQuery = (query, search, sorts, filters, section, profile) => {
  if (search.trim().length) {
    query.textSearch("folder_search", search.trim() + ':*')
  }

  if (!sorts.length) {
    if (section === "completed") {
      query.order('completed_at', { ascending: false })
    } else if (!profile.universus) {
      query.order('updated_at', { ascending: false })
    } else {
      query.order('emergency', { ascending: true })
      query.order('created_at', { ascending: true })
    }
  } else {
    sorts.forEach((sort) => {
      query.order(sort.sortField || sort.field, { ascending: sort.ascending })
    })
  }

  if (filters.length) {
    filters.filter(f => f !== null).forEach((filter) => {
      if (filter.filterOption.type === 'select') {
        query.in(filter.filterOption.field, filter.value)
      }

      if (filter.filterOption.type === 'text' && filter.value.length) {
        query.textSearch(filter.filterOption.field, filter.value + ':*')
      }

      if (filter.filterOption.type === 'date') {
        const date = filter.value.$d
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        query.eq(filter.filterOption.field, `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`)
      }

      if (filter.filterOption.type === 'boolean') {
        query.eq(filter.filterOption.field, filter.value)
      }
    })
  }
}

export const useFoldersCounts = ({ sections, search = '', sorts = [], filters = [] }) => {
  const { data: profile } = useProfile()
  const { data: settings } = useSettings()

  return useQueries({
    queries: sections.map((section) => ({
      initialData: 0,
      queryKey: ['folders', 'counts', section.value],
      queryFn: async () => {
        let query = supabase.from('folders').select('*, work:works(status)', { count: 'exact', head: true }).is('deleted_at', null)

        if (profile.company) {
          query.eq('company_id', profile.company.id)
        }

        if (section.value === 'inprogress' && !profile.universus) {
          query.in('status', ['inprogress', 'reviewing'])
        }

        if (section.value === 'inprogress' && profile.universus) {
          query.in('work.status', ['ongoing'])
          query.not('work', 'is', null)
        }

        if (section.value === 'validation' && profile.universus) {
          query.in('work.status', ['ready'])
          query.not('work', 'is', null)
        }


        if (section.value === 'available' && profile.universus) {
          query.in('work.status', !settings.permissions['read-folders'] ? ['available'] : ['available', 'processing'])
          query.not('work', 'is', null)
        }

        setQuery(query, search, sorts, filters, section.value, profile)

        const { count } = await query

        return count
      },
    })),
  })
}

export const useFolder = (id) => supabase
  .from('folders')
  .select('id, isCyclone, tags, jsonMandate, work:works(translation_path, id, report_path, report_pspdfkit, status, service, transcriber:transcribers!public_works_transcriber_id_fkey(id, profile:profiles(id, first_name, last_name, avatar, gender)), reviewer:transcribers!public_works_reviewer_id_fkey(id, profile:profiles(id, first_name, last_name, avatar, gender)), translater:transcribers!public_works_translater_id_fkey(id, profile:profiles(id, first_name, last_name, avatar, gender)), correction:corrections(result)), reference, type, speciality_id, comments, translate_to, duration, dictation_path, report_path, report_pspdfkit, folder_id, doctor:doctors(id, profile:profiles(id, first_name, last_name, avatar, gender)), company:companies(id, name), emergency, patient, status, assessment_date, speciality, created_at, completed_at, pydio_id')
  .eq('pydio_id', id)
  .is('deleted_at', null)
  .single()


export const useFolders = ({ section = '*', search = '', sorts = [], filters = [] }) => {
  const queryClient = useQueryClient()
  const { data: profile } = useProfile()
  const { data: transcriber } = useTranscriber()
  const { data: settings } = useSettings()

  return useQuery({
    queryKey: ['folders', section],
    queryFn: async () => {

      let selectQuery = 'id, reference, speciality_id, type, comments, translate_to, duration, dictation_path, report_path, report_pspdfkit, folder_id, doctor:doctors(id, profile:profiles(id, first_name, last_name, avatar, gender)), company:companies(id, name), emergency, patient, status, assessment_date, speciality, created_at, completed_at, pydio_id, isCyclone, tags'

      if (profile.universus) {
        selectQuery += ', jsonMandate, work:works(translation_path, id, report_path, report_pspdfkit, status, service, transcriber:transcribers!public_works_transcriber_id_fkey(id, profile:profiles(id, first_name, last_name, avatar, gender)), reviewer:transcribers!public_works_reviewer_id_fkey(id, profile:profiles(id, first_name, last_name, avatar, gender)), translater:transcribers!public_works_translater_id_fkey(id, profile:profiles(id, first_name, last_name, avatar, gender)), correction:corrections(result)))'
      }

      let query = supabase
        .from('folders')
        .select(selectQuery)
        .is('deleted_at', null)


      if (profile.universus && section === 'available') {
        query.in('work.status', !settings.permissions['read-folders'] ? ['available'] : ['available', 'processing'])
        query.not('work', 'is', null)
      }

      if (profile.universus && section === 'inprogress') {
        query.in('work.status', ['ongoing'])
        query.not('work', 'is', null)
      }

      if (profile.universus && section === 'validation') {
        query.in('work.status', ['ready'])
        query.not('work', 'is', null)
      }

      if (!profile.universus && section === 'inprogress') {
        query.in('status', ['inprogress', 'reviewing'])
      }

      if (section === 'completed') {
        query.eq('status', 'completed')

        if (profile.universus && !settings.permissions['read-folders']) {
          query.or(`transcriber_id.eq.${transcriber.id}, reviewer_id.eq.${transcriber.id}, translater_id.eq.${transcriber.id}`, { referencedTable: 'works' })
          query.not('work', 'is', null)
        }
      }

      if (profile.company) {
        query.eq('company_id', profile.company.id)
      }

      setQuery(query, search, sorts, filters, section, profile)

      const { data } = await query

      const filteredData = section === 'available' && !settings.permissions['read-folders'] ? transcriptionsFilter(data, transcriber) : data

      queryClient.setQueryData(['folders', 'counts', section], filteredData.length)

      // return data.map((d) => stringifyIds(d))
      return filteredData
    },
    initialData: () => {
      if (section === '*') {
        return []
      }

      const all = queryClient.getQueryData(['folders', '*'])

      if (!all) {
        return []
      }

      return all.filter((item) => item.status === section)
    },
  })
}

export const useInsertFolder = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { messageApi } = useUI()
  const { session } = useAuth()
  const { data: profile } = useProfile()
  const { pathname } = useLocation()

  return useMutation({
    mutationFn: async (values) => {
      const formData = new FormData()

      // values.folders = Array(10).fill().map((_, index) => ({...values.folders[0], reference: values.folders[0].reference + '-' + index }));

      values.folders.forEach((item, item_key) => {
        if (session.user.user_metadata.doctor_id) {
          formData.append(
            `[${item_key}][doctor_id]`,
            session.user.user_metadata.doctor_id
          )
        } else {
          if (isNaN(item.doctor_id)) {
            formData.append(
              `[${item_key}][new_doctor]`,
              item.doctor_id
            )
          } else {
            formData.append(
              `[${item_key}][doctor_id]`,
              item.doctor_id
            )
          }
        }


        formData.append(
          `[${item_key}][company_id]`,
          values.company_id
        )

        if (item.assessment_date) {
          const date = item.assessment_date.$d
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();

          formData.append(
            `[${item_key}][assessment_date]`,
            `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
          )
        }

        Object.keys(item).filter(key => !['tags', 'doctor_id', 'assessment_date', 'doctor', 'files', 'doctor_name'].includes(key) && typeof item[key] !== 'undefined').forEach((key) => {
          formData.append(`[${item_key}][${key}]`, item[key])
        })

        Object.keys(item).filter(key => ['tags'].includes(key) && typeof item[key] !== 'undefined').forEach((key) => {
          formData.append(`[${item_key}][${key}]`, JSON.stringify(item[key]))
        })

        if (item.files) {
          item.files.forEach((file, file_key) => {
            formData.append(`[${item_key}][files][${file_key}]`, file.originFileObj, file.name)
          })
        }
      })

      // const { data, error } = await supabase.functions.invoke('create-folder', {
      //   body: formData
      // })

      const { data, error } = await fetch(`${APP_API_URL}/create-folder`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${session.access_token}`,
        },
        body: formData,
      }).then(res => res.json())

      if (error) {
        throw new Error(error.message)
      } else {
        return data
      }
    },
    onSuccess: (result, values, context) => {
      const key = profile.universus ? 'available' : 'inprogress'
      queryClient.setQueryData(['folders', 'counts', key], (old) => old + 1)

      let inprogress = queryClient.getQueryData(['folders', key])

      if (inprogress) {
        inprogress = [...result, ...inprogress]
        queryClient.setQueryData(['folders', key], inprogress)
      } else {
        queryClient.setQueryData(['folders', key], result)
      }

      messageApi.open({
        type: 'success',
        content: t(`folders.toasts.${result[0].type}-created`),
        duration: 2,
      })
    },
    onError: (error, values, context) => {
      messageApi.open({
        type: 'error',
        content: error.message,
        duration: 4,
      })
    },
  })
}

export const useUpdateFolder = () => {
  const queryClient = useQueryClient()
  const { setPageLoading, messageApi } = useUI()
  const { pathname } = useLocation()
  const { session } = useAuth()
  const { t } = useTranslation()


  return useMutation({
    mutationFn: async (values) => {
      const formData = new FormData()

      values = Object.keys(values.folders[0]).reduce((a, key) => ['tags', 'speciality_id', 'assessment_date', 'files', 'id', 'patient', 'reference', 'comments', 'emergency'].includes(key) ? ({ ...a, [key]: values.folders[0][key] }) : a, {})
      if (values.assessment_date) {
        const date = values.assessment_date.$d
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        formData.append(
          `[0][assessment_date]`,
          `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
        )
      }

      Object.keys(values).filter(key => !['tags', 'doctor_id', 'assessment_date', 'doctor', 'files', 'doctor_name'].includes(key) && typeof values[key] !== 'undefined').forEach((key) => {
        if (values[key] !== null) {
          formData.append(`[0][${key}]`, values[key])
        }
      })

      Object.keys(values).filter(key => ['tags'].includes(key) && typeof values[key] !== 'undefined').forEach((key) => {
        if (values[key] !== null) {
          formData.append(`[0][${key}]`, JSON.stringify(values[key]))
        }
      })

      if (values.files) {
        values.files.filter(file => file.lastModified).forEach((file, file_key) => {
          formData.append(`[0][files][${file_key}]`, file.originFileObj || file, file.name)
        })
      }

      // const { data, error } = await supabase.functions.invoke('create-folder', {
      //   body: formData
      // })

      const { data, error } = await fetch(`${APP_API_URL}/update-folder`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${session.access_token}`,
        },
        body: formData,
      }).then(res => res.json())

      if (error) {
        throw new Error(error.message)
      } else {
        return data
      }
    },
    onSuccess: (result, values, context) => {
      let section = queryClient.getQueryData(['folders', 'inprogress'])
      let section2 = queryClient.getQueryData(['folders', 'completed'])
      let section3 = queryClient.getQueryData(['folders', 'available'])

      if (section) {
        section = section.map((item) => (item.id === result.id ? { ...item, ...result } : item))
        queryClient.setQueryData(['folders', 'inprogress'], section)
      }

      if (section2) {
        section2 = section2.map((item) => (item.id === result.id ? { ...item, ...result } : item))
        queryClient.setQueryData(['folders', 'completed'], section2)
      }

      if (section3) {
        section3 = section3.map((item) => (item.id === result.id ? { ...item, ...result } : item))
        queryClient.setQueryData(['folders', 'available'], section3)
      }

      messageApi.open({
        type: 'success',
        content: t(`folders.toasts.${result.type}-updated`),
        duration: 2,
      })
    },
    onError: (error, values, context) => {
      messageApi.open({
        type: 'error',
        content: error.message,
        duration: 4,
      })
    },
  })
}

export const useRemoveFolder = () => {
  const { pathname } = useLocation()
  const queryClient = useQueryClient()
  const { setPageLoading, messageApi } = useUI()
  const { session } = useAuth()
  const { data: transcriber } = useTranscriber()


  return useMutation({
    mutationFn: async (values) => {
      const { data, error } = await supabase.from('folders').update({ deleted_at: new Date() }).eq('id', values.id)


      if (error) {
        throw new Error(error.message)
      }

      await fetch(`${APP_API_URL}/delete-folder/${values.id}`, {
        headers: {
          Authorization: `Bearer ${session.access_token}`,
        },
      })

      if (transcriber?.current?.id === values.id ) {
        queryClient.setQueryData(['transcriber'], (old) => ({ ...old, current: null }))
      }

      return data
    },
    onMutate: (values) => {
      let sectionKey = find(FoldersSections, { path: pathname.includes('transcription') || pathname.includes('report') ? '/inprogress' : pathname.split('/')[1] }).value
      let all = queryClient.getQueryData(['folders', '*'])
      let section = queryClient.getQueryData(['folders', sectionKey])
      const oldItem = find(section, { id: values.id })
      if (all || section) {
        queryClient.setQueryData(['folders', 'counts', '*'], (old) => old - 1)
      }
      if (oldItem) {
        queryClient.setQueryData(['folders', 'counts', oldItem.status], (old) => old - 1)
      }

      if (all) {
        all = all.filter((item) => item.id !== values.id)
        queryClient.setQueryData(['folders', '*'], all)
      }


      if (sectionKey !== '*' && section) {
        section = section.filter((item) => item.id !== values.id)
        queryClient.setQueryData(['folders', sectionKey], section)
      }

      return oldItem
    },
    onError: (error, values, context) => {
      console.log(error)
      let all = queryClient.getQueryData(['folders', '*'])
      let section = queryClient.getQueryData(['folders', context.status])
      queryClient.setQueryData(['folders', 'counts', context.status], (old) => old + 1)

      if (all) {
        all = [context, ...all]
        queryClient.setQueryData(['folders', '*'], all)
      }

      if (sectionKey !== '*') {
        section = [context, ...section]
        queryClient.setQueryData(['folders', sectionKey], section)
      }

      console.log('Error', error, values, context)
      messageApi.open({
        type: 'error',
        content: error.message,
        duration: 4,
      })
    },
  })
}
